import React , {useState, useContext} from 'react';
import { Form, Button, Input, ErrorMessage } from '../components/common';
import { FirebaseContext } from '../components/Firebase';

const Register = () => {

    const {firebase} = useContext(FirebaseContext);
    const [errorMessage, setErroroMessage] = useState('');
    const [formValues, setFormValues] = useState({email: '', password: '', confirmPassword: '', username: ''});

    const handleImputChange = (e)=>{
        e.persist();

        setFormValues(currentValues =>({
            ...currentValues,
            [e.target.name] : e.target.value
        }))
        setErroroMessage('');
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(formValues.password === formValues.confirmPassword){
            firebase.register({
                email: formValues.email,
                password: formValues.password,
                username: formValues.username,
            }).catch(error =>{
                setErroroMessage(error.message);
           })
        }else{
            setErroroMessage("Password and confirm Password fields must match");
        }
    }

    return(
        <Form onSubmit={handleSubmit}>
            <Input value={formValues.username} type="text" placeholder="Username" require minLength={3} onChange={handleImputChange} name="username"/>
            <Input value={formValues.email} type="email" placeholder="email" require onChange={handleImputChange} name="email" />
            <Input value={formValues.password} type="password" placeholder="password" require minLength={6} onChange={handleImputChange} name="password"/>
            <Input value={formValues.confirmPassword} type="password" placeholder="confirm password" require minLength={6} onChange={handleImputChange} name="confirmPassword"/>
            
            {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Button block type="submit" >Register</Button> 
        </Form>
    )
}

export default Register;